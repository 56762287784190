angular.module('comcast.common.aspera').service('AsperaService', ['NotificationService', function (NotificationService) {
  var self = this;
  self.uready = false;
  self.scope = {};
  self.spot = {};
  self.asperaEventObj = {};
  self.initConnect = initConnect;
  self.handleUploadCallback = handleUploadCallback;
  self.handleUploadCancel = handleUploadCancel;

  function initConnect(id, callback, caller, spec) {
    var CONNECT_INSTALLER = '//d3gcli72yxqn2z.cloudfront.net/connect/v4';
    var asperaWeb = new AW4.Connect({
      sdkLocation: CONNECT_INSTALLER,
      minVersion: '3.6.0',
      id: 'aspera_web_transfers-' + id
    });
    var asperaInstaller = new AW4.ConnectInstaller({
      sdkLocation: CONNECT_INSTALLER
    });

    var statusEventListener = function statusEventListener(eventType, data) {
      var status = AW4.Connect.STATUS;

      if (eventType === AW4.Connect.EVENT.STATUS) {
        if (data === status.INITIALIZING) {
          asperaInstaller.showLaunching();
        }

        if (data === status.FAILED) {
          asperaInstaller.showDownload();
        }

        if (data === status.OUTDATED) {
          asperaInstaller.showUpdate();
        }

        if (data === status.RUNNING) {
          asperaInstaller.connected(); // eslint-disable-next-line

          callback(caller, spec, asperaWeb, id);
        }
      }
    };

    asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener);
    asperaWeb.initSession('nodeConnect-' + id);
  }

  self.handleUpload = function (spec, scope, spot) {
    self.scope = scope;
    self.spot = spot;
    var random = Math.floor(Math.random() * 10000 + 1);
    self.initConnect(random, handleUploadCallback, undefined, spec);
  };

  self.handleCancel = function (eventObj) {
    var random = Math.floor(Math.random() * 10000 + 1);
    self.initConnect(random, handleUploadCancel, undefined, eventObj);
  };

  function handleUploadCallback(caller, spec, asperaWeb, random) {
    //random is a random number used for creating multiple instances of downloads/uploads
    var fileControls = {}; //Progress bar handler

    fileControls.handleTransferEvents = function (event, returnObj) {
      var obj = returnObj.transfers[0];

      if (!obj) {
        obj = {};
        self.uready = false;
      } else if (obj.status == 'failed') {
        self.scope.$apply(function () {
          self.scope.handleAsperaEvent(obj);
        });
      }

      if (obj.status == 'completed') {
        self.scope.$apply(function () {
          self.scope.handleAsperaEvent(obj);
        });
      }

      self.asperaEventObj = obj; //Wait for initiating status to avoid reading past events, 'uready' lets us know when we start

      if (obj.status == 'initiating' || obj.previous_status == 'initiating') {
        self.uready = true;

        if (obj.status == 'initiating') {
          self.scope.handleAsperaEvent(obj);
          NotificationService.showNotificationToast('Aspera Upload initiating');
        }
      }

      if (self.uready) {
        //Received failed, make background red and print error message.  Operation is done.
        if (obj.status == 'failed') {
          self.uready = false;
        } else if (obj.status == 'completed') {
          //Received completed, make background green and print complete message.  Operation is done.
          NotificationService.showNotificationToast('Aspera Upload completed');
          self.uready = false; //Remove transfer event listener

          asperaWeb.removeEventListener('transfer');
        } else {
          //Transfer in progress
          switch (event) {
            case 'transfer':
              if (!isNaN(obj.percentage)) {
                self.scope.$apply(function () {
                  self.scope.handleAsperaEvent(obj);
                });
              }

              break;
          }
        }
      }
    };

    asperaWeb.addEventListener('transfer', fileControls.handleTransferEvents); //Block Connect Dialog from appearing, since we are showing progress on web app

    var connectSettings = {
      allow_dialogs: 'no'
    }; //Start Upload using Connect

    var transferSpec = spec; // eslint-disable-next-line

    fileControls.renameFileWithISCI = function (pathArray, transferSpec) {
      var fileArray = pathArray.dataTransfer.files;
      var str;

      if (navigator.appVersion.indexOf('Mac') != -1) {
        str = fileArray[0].name.split('/');
      } else {
        str = fileArray[0].name.split('\\');
      }

      for (var i = 0; i < fileArray.length; i++) {
        transferSpec.paths[i] = {
          source: fileArray[i].name
        };
      }

      var fullFname = str[str.length - 1];
      var fnameArr = fullFname.split('.');
      transferSpec.destination_root = self.spot.isci + '.' + fnameArr[1];
    }; //Show Select File dialog box and loop through each file to add it to path array.


    asperaWeb.showSelectFileDialog({
      success: function success(pathArray) {
        fileControls.renameFileWithISCI(pathArray, transferSpec);
        asperaWeb.startTransfer(transferSpec, connectSettings);
      }
    });
  }

  function handleUploadCancel(caller, eventObj, asperaWeb, random) {
    asperaWeb.stopTransfer(eventObj.uuid);
    asperaWeb.removeTransfer(eventObj.uuid);
  }
}]);